export const PharmacistRetailChains = [
  'shoppers-drug-mart',
  'pharma_save',
  'rexall',
  'walmart',
  'costco',
  'famili_prix',
  'jean_coutu',
  'uniprix',
  'remedy-rx',
  'guardian-drugs',
  'ida',
  'loblaws',
  'pharmachoice',
  'whole-health',
  'other',
  'independent_pharmacy',
  'walgreens',
  'cvs',
  'rite_aid',
  'kroger',
  'albertsons',
  'amerisource_bergen',
  'publix',
  'mc_kesson',
  'cardinal_health',
] as const;

export type PharmacistRetailChain = (typeof PharmacistRetailChains)[number];
