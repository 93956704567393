export const PharmacistPracticeSpecialties = [
  'ambulatory_care',
  'oncology',
  'infectious_disease',
  'pediatrics',
  'other',
] as const;

export type PharmacistPracticeSpecialty =
  (typeof PharmacistPracticeSpecialties)[number];
