export const NursePracticeSettings = [
  'academic_hospital',
  'community_hospital',
  'community_teaching_hospital',
  'clinic_practice',
  'community_health',
  'home_care',
  'other',
] as const;

export type NursePracticeSetting = (typeof NursePracticeSettings)[number];
