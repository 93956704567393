export const AdBoardInviteStates = [
  'draft', // when an admin creates an invite
  'sent', // when an admin has sent an invite
  'contract-signed', // when a participant has signed the contract in the app, but we're still waiting on Docusign confirmation
  'declined', // when a participant has declined the invite in the app
  'contract-sent-error', // When an error occurred during the contract generation
  'confirmed', // when Docusign has confirmed the contract has effectively been signed - Participant can acess KoLab
  'failed-contract-confirmation', // When an error occurred with Docusign confirmation; we treat it as "confirmed" though
] as const;

export type AdBoardInviteState = (typeof AdBoardInviteStates)[number];
