// Restrict to pdf, zip, and office docs.
export const AttachmentsTypes = [
  'application/pdf',

  // ZIP
  'application/zip',
  'application/x-zip-compressed',
  'multipart/x-zip',

  // Office Documents
  'application/msword', // DOC
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
  'application/vnd.ms-excel', // XLS
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
  'application/vnd.ms-powerpoint', // PPT
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPTX
] as const;

export type AttachmentsType = (typeof AttachmentsTypes)[number];
